require("./bootstrap");

const tp = document.querySelectorAll("span.tp");
const em = document.querySelectorAll("span.em");

em.forEach(el => {
    const s =
        "=b!isfg>#nbjmup;gj{kp/t{zcjtuzAhnbjm/dpn#?gj{kp/t{zcjtuzAhnbjm/dpn=0b?";
    let m = "";
    for (var i = 0; i < s.length; i++)
        m += String.fromCharCode(s.charCodeAt(i) - 1);
    el.innerHTML = m;
});

tp.forEach(el => {
    const s = "=b!isfg>#ufm;,59614491542#?614!491!542=0b?";
    let m = "";
    for (var i = 0; i < s.length; i++)
        m += String.fromCharCode(s.charCodeAt(i) - 1);
    el.innerHTML = m;
});
